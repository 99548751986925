<script setup lang="ts">

const { status, data, signIn, signOut } = useAuth();

const isAuth = computed(() => status.value === "authenticated");

</script>
<template>
  <div class="flex h-screen w-screen flex-col justify-between">
    <nav class="flex items-center justify-between bg-teal-800 p-6">
      <NuxtLink to="/" class="text-xl text-white select-none">WheeTime</NuxtLink>
      <div v-if="isAuth" class="flex items-center space-x-4">
        <action-menu/>
      </div>
      <div v-else class="flex items-center space-x-4">
        <NuxtLink to="/user/login" class="cursor-pointer select-none text-white hover:text-teal-300">Login</NuxtLink>
      </div>
    </nav>
    <div class="flex flex-grow flex-col items-center overflow-x-hidden">
        <div class="w-fit h-fit">
          <slot />
        </div>
    </div>
    <footer class="flex flex-wrap items-center justify-between bg-gray-900 p-6">
      <NuxtLink to="/imprint" class="cursor-pointer select-none text-white hover:text-teal-300">Imprint</NuxtLink>
      <div v-if="isAuth">
        <div class="flex items-center space-x-4 md:mt-0">
        </div>
      </div>
      <div v-else>
        <div class="flex items-center space-x-4 md:mt-0">
        </div>
      </div>
    </footer>
  </div>
</template>
