<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { ref } from 'vue'
import {
  AvatarFallback,
  AvatarImage,
  AvatarRoot,
  DropdownMenuArrow,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemIndicator,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from "radix-vue";

const { status, data, signOut } = useAuth();

const userImage = computed(() => data.value?.user?.image);
const router = useRouter();

const nameInitials = computed(() => {
  const name = data.value?.user?.name?.trim() || "";
  if(name === "") return "USR";
  return name
    .split(" ")
    .map((n) => n[0])
    .join("");
});

const toggleState = ref(false)
const checkboxOne = ref(false)
const checkboxTwo = ref(false)
const person = ref('pedro')

function handleClick() {
  // eslint-disable-next-line no-alert
  alert('hello!')
}
</script>

<template>
  <DropdownMenuRoot v-model:open="toggleState">
    <DropdownMenuTrigger aria-label="Customise options">
      <AvatarRoot class="bg-blackA3 inline-flex h-[45px] w-[45px] select-none items-center justify-center overflow-hidden rounded-full align-middle">
        <AvatarImage
          class="h-full w-full rounded-[inherit] object-cover"
          :src="userImage || ''"
          alt="Avatar"
        />
        <AvatarFallback
          class="text-black leading-1 flex h-full w-full items-center justify-center bg-white text-lg font-medium"
          :delay-ms="600"
        >
          {{ nameInitials }}
        </AvatarFallback>
      </AvatarRoot>
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="min-w-[220px] outline-none bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
        :side-offset="5"
      >
        <DropdownMenuItem
          value="My Account"
          class="group text-lg leading-none text-black rounded-[3px] flex items-center h-9 px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-teal-600 data-[highlighted]:text-green1"
          @click="router.push('/user/account')"
        >
          my account
        </DropdownMenuItem>
        <DropdownMenuItem
          value="Group invites"
          class="group text-lg leading-none text-black rounded-[3px] flex items-center h-9 px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-teal-600 data-[highlighted]:text-green1"
          @click="router.push('/user/invites')"
        >
          group invites
        </DropdownMenuItem>
        <DropdownMenuSeparator class="h-[1px] bg-green6 m-[5px]" />
        <DropdownMenuItem
          value="New group"
          class="group text-lg leading-none text-black rounded-[3px] flex items-center h-9 px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-teal-600 data-[highlighted]:text-green1"
          @click="router.push('/group/create')"
        >
          new group
        </DropdownMenuItem>
        <DropdownMenuItem
          value="My groups"
          class="group text-lg leading-none text-black rounded-[3px] flex items-center h-9 px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-teal-600 data-[highlighted]:text-green1"
          @click="router.push('/group/list-my')"
        >
          my groups

        </DropdownMenuItem>
        <DropdownMenuItem
          value="Find groups"
          class="group text-lg leading-none text-black rounded-[3px] flex items-center h-9 px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-teal-600 data-[highlighted]:text-green1"
          @click="router.push('/group/')"
        >
          find groups
        </DropdownMenuItem>
        <DropdownMenuSeparator class="h-[1px] bg-green6 m-[5px]" />
        <DropdownMenuItem
          value="Logout"
          class="group text-lg leading-none text-black rounded-[3px] flex items-center h-9 px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-teal-600 data-[highlighted]:text-green1"
          @click="signOut()"
        >
          logout
        </DropdownMenuItem>
        <DropdownMenuArrow class="fill-white"/>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>